import React from 'react';

import Layout from 'src/components/layout/layout';
import { PlayerPoints } from 'src/components/player-points/player-points';
import { PrivateRoute } from 'src/components/private-route/private-route';
import SEO from 'src/components/seo';

const PlayerPointsPage = () => {
  return (
    <Layout>
      <SEO title="Player points" />
      <PrivateRoute>
        <PlayerPoints />
      </PrivateRoute>
    </Layout>
  );
};

export default PlayerPointsPage;
